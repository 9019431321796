// POST DETAIL PAGE TEMPLATE
import React from 'react'
import formatDate from '../utils/formatDate'
import NavelImage from '../components/NavelImage'
import TemplateWrapper from '../components/Layout'
import TheButton from '../components/TheButton'

import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import TagListing from '../components/TagListing'

export default ({ data, pageContext }) => {
  const post = data.mdx

  return (
    <TemplateWrapper>
      <div className="post-wrap">
        <div
          className={
            'post' + (post.frontmatter.primaryImage ? '' : ' no-image')
          }
        >
          {post.frontmatter.primaryImage && (
            <NavelImage
              image={post.frontmatter.primaryImage}
              imageClass="post-primary-image"
            />
          )}

          <div className="post-content">
            <div className="post-header">
              <div className="post-header-column">
                <h1 className="post-title">{post.frontmatter.title}</h1>
                <div className="post-subtitle">{post.frontmatter.subtitle}</div>
              </div>
              <div className="post-header-column">
                {post.frontmatter.infoLink && (
                  <TheButton
                    link={post.frontmatter.infoLink}
                    text={post.frontmatter.info}
                  />
                )}
                {!post.frontmatter.infoLink && (
                  <div className="post-info">{post.frontmatter.info}</div>
                )}
                {!post.frontmatter.customDate && (
                  <div className="post-date">
                    {formatDate(post.frontmatter.date, post.frontmatter.time)}
                  </div>
                )}
                {post.frontmatter.customDate && (
                  <div className="post-date">{post.frontmatter.customDate}</div>
                )}
                {post.frontmatter.customDate && (
                  <div className="post-time">{post.frontmatter.time}</div>
                )}
              </div>
            </div>

            <div className="post-body">
              <MDXRenderer gallery={post.frontmatter.galleryImages}>
                {post.body}
              </MDXRenderer>

              {post.frontmatter.eventType !== null && (
                <div className="post-ongoing">
                  <TagListing
                    parent={data.parent}
                    children={data.children}
                    pageContext={pageContext}
                    frontMatter={post.frontmatter}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </TemplateWrapper>
  )
}

export const query = graphql`
  query Events($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      id
      frontmatter {
        title
        subtitle
        date
        customDate
        time
        info
        eventType {
          parent
          assemblies
          childOf
          ongoing
        }
        infoLink
        showOnHomepage
        galleryImages {
          publicURL
          childImageSharp {
            fixed(width: 780, quality: 70) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
        primaryImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    parent: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/events/" }
        frontmatter: { eventType: { parent: { ne: null } } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            eventType {
              parent
              assemblies
              childOf
              ongoing
            }
            customDate
            time
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    children: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/events/" }
        frontmatter: { eventType: { childOf: { ne: null } } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            eventType {
              parent
              assemblies
              childOf
              ongoing
            }
            customDate
            time
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
  }
`
