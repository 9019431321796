import React from 'react'
import is_upcoming from '../../utils/upcoming'
import ListingItem from '../ListingItem'

class TagListing extends React.Component {
  render() {
    let parentList = this.props.parent
    let childList = this.props.children

    let upcomingParent = []
    let pastParent = []
    let upcomingChildren = []
    let pastChildren = []

    let assembliesTag = []

    let parent = this.props.frontMatter.eventType.parent
    let child = this.props.frontMatter.eventType.childOf

    let tagLabel =
      parent === 'queering-death' || parent === 'digital-matterealities'
        ? 'Related Events'
        : 'Past'

    let assembliesLabel =
      child === 'assemblies-spring2020'
        ? 'Spring 2020 Assemblies'
        : child === 'assemblies-fall2020'
        ? 'Fall 2020 Assemblies'
        : 'Past'

    if (childList !== null) {
      upcomingChildren = childList.edges
        .filter(
          (node) =>
            is_upcoming(node.node.frontmatter.date) &&
            node.node.frontmatter.eventType !== null &&
            node.node.frontmatter.eventType.childOf === parent
        )
        .reverse()
      pastChildren = childList.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) &&
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.childOf === parent
      )
    }

    if (parentList !== null) {
      upcomingParent = parentList.edges
        .filter(
          (node) =>
            is_upcoming(node.node.frontmatter.date) &&
            node.node.frontmatter.eventType !== null &&
            node.node.frontmatter.eventType.parent === child &&
            this.props.pageContext.slug !== node.node.fields.slug
        )
        .reverse()
      pastParent = parentList.edges.filter(
        (node) =>
          !is_upcoming(node.node.frontmatter.date) &&
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.parent === child &&
          this.props.pageContext.slug !== node.node.fields.slug
      )
    }

    if (childList !== null) {
      assembliesTag = childList.edges.filter(
        (node) =>
          node.node.frontmatter.eventType !== null &&
          node.node.frontmatter.eventType.assemblies &&
          node.node.frontmatter.eventType.childOf === child &&
          this.props.pageContext.slug !== node.node.fields.slug
      )
    }

    return (
      <div>
        <div
          className={
            'tags-listing' +
            (upcomingParent.length ? '' : ' no-upcoming') +
            (pastParent.length ? '' : ' no-past')
          }
        >
          <div className="tags-section-wrap parent">
            {upcomingParent.length > 0 && (
              <div className="tags-section upcoming"></div>
            )}
            <div className="tags-listing-wrap parent">
              {upcomingParent.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>

          <div className="tags-section-wrap parent">
            {pastParent.length > 0 && (
              <div className="tags-section upcoming"></div>
            )}
            <div className="tags-listing-wrap parent">
              {pastParent.map(({ node }) => {
                return <ListingItem key={node.id} node={node} past />
              })}
            </div>
          </div>
        </div>

        <div
          className={
            'tags-listing' +
            (upcomingChildren.length ? '' : ' no-upcoming') +
            (pastChildren.length ? '' : ' no-past')
          }
        >
          <div className="tags-section-wrap upcoming">
            {upcomingChildren.length > 0 && (
              <div className="tags-section upcoming">Upcoming</div>
            )}
            <div className="tags-listing-wrap">
              {upcomingChildren.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
          <div className="tags-section-wrap past">
            {pastChildren.length > 0 && (
              <div className="tags-section upcoming">{tagLabel}</div>
            )}
            <div className="tags-listing-wrap">
              {pastChildren.map(({ node }) => {
                return <ListingItem key={node.id} node={node} past />
              })}
            </div>
          </div>
        </div>

        <div
          className={
            'tags-listing' + (assembliesTag.length ? '' : ' no-upcoming')
          }
        >
          <div className="tags-section-wrap upcoming">
            {assembliesTag.length > 0 && (
              <div className="tags-section upcoming">{assembliesLabel}</div>
            )}
            <div className="tags-listing-wrap">
              {assembliesTag.map(({ node }) => {
                return <ListingItem key={node.id} node={node} />
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default TagListing
